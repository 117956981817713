<template>
  <div class="h-screen flex">
    <!-- Fixed sidebar -->
    <div class="w-60">
      <sideBarDok></sideBarDok>
    </div>

    <navBarDok />
    <div class="h-full flex flex-col w-full pb-0" style="padding-top: 71px;">
      <router-view v-slot="{ Component }">
        <component :is="Component" />
      </router-view>
    </div>
  </div>
</template>

<script>
import sideBarDok from "@/layouts/newdashboard/layouts/sidebar/index.vue";
import navBarDok from "@/layouts/newdashboard/layouts/navbar/index.vue";

export default {
  components: { sideBarDok, navBarDok }
};
</script>